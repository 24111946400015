import { render, staticRenderFns } from "./PageLayout.vue?vue&type=template&id=936f2a46&lang=pug&"
import script from "./PageLayout.vue?vue&type=script&lang=js&"
export * from "./PageLayout.vue?vue&type=script&lang=js&"
import style0 from "./PageLayout.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
installComponents(component, {VCard,VCardText,VContainer,VDialog,VFlex,VFooter})
